export const batchLink = async (task_ids, wsid, recursive, space_title) => {
    // Note: task_ids is expected to be a comma-separated string of task IDs, not a array
    let url = getUrl(`/api/tasks/batchLink/`);
    const result = await logFetchAJAX(url, {
        method: 'POST',
        body: {
            ids: task_ids,
            wsid: wsid,
            recursive: recursive
        }
    });
    
    await logUserActivity({
        "pageURL": getCurrentURL(),
        "human_friendly_message": `Batch link multitple tasks to workspace ${wsid} - ${space_title} - recursive: ${recursive}`,
        "action": "batchLink",
        "new_value": task_ids,
        "referrer": document.referrer
    },true);
    
    return result;
}