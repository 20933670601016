export const upvote = async (taskId) => {
    // A little hack to handle changes in commands
    const response = await logFetchAJAX(getUrl(`/api/tasks/${taskId}/upvote`), {
      method: "GET"
    });

    logUserActivity({
      "pageURL": getCurrentURL(),
      "human_friendly_message": `Upvote or withdraw on task ${taskId}`,
      "action": "upvote",
      "resource": `/tasks/${taskId}`,
      "referrer": document.referrer
  });

    return response;
}
