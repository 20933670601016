export const getStaticLogoList = () => {
    return {
        'dagknows': {
            'logo': '/vstatic/images/icons/dagknows_logo_icon.png',
            'display_label': 'Dagknows'
        },
        'aws': {
            'logo': '/static/aws.png',
            'display_label': 'AWS'
        },
        'aim': {
            'logo': '/vstatic/images/icons/aws_aim.svg',
            'display_label': 'AWS AIM'
        },
        'cloudfront': {
            'logo': '/vstatic/images/icons/aws_cloud_front.svg',
            'display_label': 'CloudFront'
        },
        'cloudtrail': {
            'logo': '/vstatic/images/icons/aws_cloudtrail.svg',
            'display_label': 'CloudTrail'
        },
        'cloudwatch': {
            'logo': '/vstatic/images/icons/aws_cloudwatch.svg',
            'display_label': 'CloudWatch'
        },
        'dynamodb': {
            'logo': '/vstatic/images/icons/aws_dynamodb.svg',
            'display_label': 'DynamoDB'
        },
        'docusign': {
            'logo': '/vstatic/images/icons/docusign.png',
            'display_label': 'Docusign'
        },
        'ec2': {
            'logo': '/vstatic/images/icons/ec2.svg',
            'display_label': 'AWS EC2'
        },
        'ebs': {
            'logo': '/vstatic/images/icons/aws_elastic_block_store.svg',
            'display_label': 'AWS EBS'
        },
        'ecs': {
            'logo': '/vstatic/images/icons/aws_ecs.svg',
            'display_label': 'ECS'
        },
        'elb': {
            'logo': '/vstatic/images/icons/aws_elb.svg',
            'display_label': 'ELB'
        },
        'iam': {
            'logo': '/vstatic/images/icons/aws_iam.svg',
            'display_label': 'IAM'
        },
        'rds': {
            'logo': '/vstatic/images/icons/aws_rds.svg',
            'display_label': 'RDS'
        },
        'redshift': {
            'logo': '/vstatic/images/icons/aws_redshift.svg',
            'display_label': 'Redshift'
        },
        'route53': {
            'logo': '/vstatic/images/icons/aws_route53.svg',
            'display_label': 'Route 53'
        },
        'storage gateway': {
            'logo': '/vstatic/images/icons/aws_storage_gateway.svg',
            'display_label': 'AWS Storage gateway'
        },
        's3': {
            'logo': '/vstatic/images/icons/aws_s3.svg',
            'display_label': 'S3'
        },
        'ssm': {
            'logo': '/vstatic/images/icons/aws_system_manager.svg',
            'display_label': 'SSM'
        },
        'vpc': {
            'logo': '/vstatic/images/icons/aws_vpc.svg',
            'display_label': 'ECS'
        },
        'azure': {
            'logo': '/static/azure.png',
            'display_label': 'Azure'
        },
        'cisco': {
            'logo': '/static/cisco.png',
            'display_label': 'Cisco'
        },
        'confluence': {
            'logo': '/static/confluence.png',
            'display_label': 'Confluence'
        },
        'docker': {
            'logo': '/static/docker.png',
            'display_label': 'Docker'
        },
        'elastic': {
            'logo': '/static/elastic.png',
            'display_label': 'Elasticsearch'
        },
        'elk': {
            'logo': '/static/elk.png',
            'display_label': 'ELK'
        },
        'gcp': {
            'logo': '/static/gcp.png',
            'display_label': 'GCP'
        },
        'jenkins': {
            'logo': '/vstatic/images/icons/jenkins.svg',
            'display_label': 'Jenkins'  
        },
        'slack': {
          'logo': '/vstatic/images/icons/btn-login-slack.png',
          'display_label': 'Slack'
        },
        'github': {
            'logo': '/static/github.png',
            'display_label': 'Github'
        },
        'jira': {
            'logo': '/static/jira.png',
            'display_label': 'JIRA'
        },
        'zendesk': {
            'logo': '/vstatic/images/icons/zendesk.svg',
            'display_label': 'Zendesk'
        },
        'kubernetes': {
            'logo': '/static/kuberenetes.png',
            'display_label': 'Kubernetes'
        },
        'linux': {
            'logo': '/static/linux.png',
            'display_label': 'Linux'
        },
        'macos': {
            'logo': '/static/macos.png',
            'display_label': 'Mac OS'
        }, 
        'msteams': {
            'logo': '/static/msteams.png',
            'display_label': 'MS Teams'
        },
        'opsgenie': {
            'logo': '/static/opsgenie.png',
            'display_label': 'Opsgenie'
        },
        'openvpn': {
            'logo': '/vstatic/images/icons/openvpn.png',
            'display_label': 'OpenVPN'
        },
        'pagerduty': {
            'logo': '/static/pagerduty.png',
            'display_label': 'PagerDuty'
        },
        'postgresql': {
            'logo': '/static/postgres.png',
            'display_label': 'PostgreSQL'
        },
        'prometheus': {
            'logo': '/static/prometheus.png',
            'display_label': 'Prometheus'
        },
        'servicenow': {
            'logo': '/static/images/servicenow.png',
            'display_label': 'ServiceNow'
        },
        'ubuntu': {
            'logo': '/static/ubuntu.png',
            'display_label': 'Ubuntu'
        },
        'windows': {
            'logo': '/vstatic/images/icons/windows.png',
            'display_label': 'Windows'
        }

    }
}
