<template>
    <div>
        <!--components/LinkTasksToSpace.vue-->
        <div>
            <table class="block-center">
                <tbody>
                    <tr>
                        <td>Link to:&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td>
                            <select v-model="selected_space" class="form-select" id="link_to_space_dropdown" @change="validate_space_selection">
                                <option value="">Choose workspace</option>
                                <option :value="space.id" v-for="(space, index) in spaces">
                                    {{ space.title }}
                                </option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p class="error center" v-show="show_same_space_error_ref">
            Destination workspace cannot be the same as current workspace.  Please choose a different workspace.
        </p>
        <div>
            <div class="modal_button_container">
                <button class="btn btn-primary ok-btn" @click.stop.prevent="process_ok_btn">OK</button>
                <button class="btn btn-secondary cancel-btn" @click.stop.prevent="cancel">Cancel</button>
            </div>
        </div>
    </div>
</template>
<script setup>
let tasks = [];
let selected_space = ref("");

let spaces = await getWorkspaces([]);
spaces = spaces.sort(
    (a, b) => {
        if (a.title < b.title) {
            return -1;
        } else if (a.title > b.title) {
            return 1;
        } else {
            return 0;
        }
    }
);

onMounted(async () => {
    let tasks = [];
    document.querySelectorAll('.task_checkboxes').forEach((elem) => {
        if (elem.checked) {
            tasks.push(elem.value)
        }
    });

    if (tasks.length == 0) {
        dkAlert("You did not select any task!")
    } else {
        showGenericModal();
    }    
});

const show_link_task_dialog_state = useShowLinkTasksDialog();
const show_same_space_error_ref = ref(false);

const validate_space_selection = () => {
    let current_space = getWorkspaceFromDropdown();
    if (current_space == selected_space.value) {
        show_same_space_error_ref.value = true;
    } else {
        show_same_space_error_ref.value = false;
    }
}

const cancel = () => {
    show_link_task_dialog_state.value = false;
    hideGenericModal();
}

const process_ok_btn = () => {
    let tasks = [];
    document.querySelectorAll('.task_checkboxes').forEach((elem) => {
        if (elem.checked) {
        tasks.push(elem.value)
        }
    });

    let link_to_space_dropdown_select = document.getElementById('link_to_space_dropdown');
    let link_to_space_dropdown_selected_option = link_to_space_dropdown_select.options[link_to_space_dropdown_select.selectedIndex];
    let link_to_space_dropdown_selected_option_label = link_to_space_dropdown_selected_option.text;
    let selected_space_title = link_to_space_dropdown_selected_option_label;
    if (selected_space_title == 'Choose workspace') {
        selected_space_title = 'Default'
    }

    let current_space = getWorkspaceFromDropdown();
    if (current_space == selected_space.value) {
        show_same_space_error_ref.value = true;
        return;
    } else {
        show_same_space_error_ref.value = false;
    }
    // selected_space
    let selected_tasks = tasks.join(',')
    dkConfirm(`Are you sure that you want link the selected tasks to "${selected_space_title}" workspace?`, async function(){
        show_link_task_dialog_state.value = false;
        hideGenericModal();

        await showWaitPage();
        let result = await batchLink(selected_tasks, selected_space.value, true, selected_space_title);
        let waitTime = tasks.length * 200;
        if (waitTime > 2000) {
            waitTime = 2000;
        }
        if (waitTime < 1000) {
            waitTime = 1000; // Enforce a minimum wait time
        }
        await dummyWait(waitTime); // Wait for the backend to delete the selected tasks
        hideWaitPage();
        hideGenericModal();
    }, function(){});

}
</script>