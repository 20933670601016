export const batchMove = async (task_ids, wsid, recursive, current_wsid, selected_space_title, current_space_title) => {
    // Note: task_ids is expected to be a comma-separated string of task IDs, not a array
    let url = getUrl(`/api/tasks/batchMove/`);
    const result = await logFetchAJAX(url, {
        method: 'POST',
        body: {
            ids: task_ids,
            wsid: wsid,
            from_wsid: current_wsid,
            recursive: recursive
        }
    });

    await logUserActivity({
        "pageURL": getCurrentURL(),
        "human_friendly_message": `Batch move multitple tasks to workspace ${wsid} - ${selected_space_title} from ${current_wsid} - ${current_space_title} - recursive: ${recursive}`,
        "action": "batchMove",
        "new_value": task_ids,
        "referrer": document.referrer
    },true)

    return result;
}