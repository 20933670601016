export const deleteTasks = async (task_ids) => {
    let url = getUrl(`/api/tasks/batchDelete/`);
    const result = await logFetchAJAX(url, {
        method: 'POST',
        body: {
            ids: task_ids
        }
    });

    await logUserActivity({
        "pageURL": getCurrentURL(),
        "human_friendly_message": `Batch delete multitple tasks`,
        "action": "deleteTasks",
        "old_value": task_ids,
        "referrer": document.referrer
    },true)

    return result;
}