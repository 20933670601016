export const getTagMapping = () => {
    let mapping = {
        'dagknows': ['dagknows','dk'],
        'aws': ['aws', 'amazon'],
        'azure': ['azure'],
        'cisco': ['cisco'],
        'elastic': ['elastic', 'elasticsearch'],
        'kubernetes': ['kubernetes', 'k8s', 'kubectl'],
        'linux': ['linux'],
        'macos': ['macos'],
        'docusign': ['docusign'],
        'openvpn': ['openvpn'],
        'windows': ['windows'],
        'prometheus': ['prometheus'],
        'ubuntu': ['ubuntu'],
        'gcp': ['gcp', 'googlecloud'],
        'postgresql': ['postgres','postgresql'],
    };

    let logo_list = getStaticLogoList();
    Object.keys(getStaticLogoList()).forEach((vendor) => {
        if (! mapping.hasOwnProperty(vendor)) {
            mapping[vendor] = [vendor];
        }
    });

    return mapping;
}
