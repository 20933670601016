export const batchTag = async (selected_tasks, tags) => {
    let url = getUrl(`/api/tasks/batchTag/`);
    const result = await logFetchAJAX(url, {
        method: 'POST',
        body: {
            ids: selected_tasks,
            tags: tags
        }
    });

    await logUserActivity({
        "pageURL": getCurrentURL(),
        "human_friendly_message": `Batch tag multitple tasks`,
        "action": "batchTag",
        "new_value": JSON.stringify({
            "tasks": selected_tasks,
            "tags": tags
        }),
        "referrer": document.referrer
    },true)

    return result;

}